.content h2,
.content h3 {
  display: inline;
  margin-right: 4px;
  font-size: 16px;
}

.content h2::after,
.content h3::after {
  content: '.';
}

.content h2 {
  text-transform: uppercase;
}

.content h3 {
  font-weight: normal;
  text-decoration: underline;
}

.content li {
  margin-bottom: 16px;
}

.content li li {
  margin-bottom: 0;
  list-style-type: lower-alpha;
}

.term {
  text-decoration: underline;
}

.actions {
  justify-content: flex-end;
}
