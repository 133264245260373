.paper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: calc(4 * var(--theme-spacing-unit));
  border-radius: 0;
}
@media only screen and (min-width: 400px) {
  .paper {
    width: 400px;
    min-height: 300px;
    border-radius: 3px;
  }
}

.centered-explanation {
  margin-top: calc(var(--theme-spacing-unit) * 4);
  font-size: 0.875rem;
  width: 100%;
  max-width: 750px;
  padding: 0 calc(var(--theme-spacing-unit) * 3);
  text-align: center;
  color: var(--theme-palette-grey-600);
}
