.button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--theme-spacing-unit);
}

.links {
  margin-top: calc(3 * var(--theme-spacing-unit));
  display: flex;
  justify-content: space-between;
}

.flex-center > * {
  vertical-align: baseline !important;
}
