/* https://css-tricks.com/international-box-sizing-awareness-day/ */
*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  font-family: Roboto, sans-serif;
  line-height: 1.4;
}

body {
  margin: 0;
  padding: 0;
}

figure {
  margin: 0;
}

textarea:focus {
  outline: none;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
