.root {
  display: flex;
  justify-content: center;
  padding-top: 111px;
}
.root h1,
.root h2,
.root h3,
.root h4 {
  margin-top: calc(3 * var(--theme-spacing-unit));
  margin-bottom: var(--theme-spacing-unit);
}
.root h1 {
  font-size: 28px;
  font-weight: normal;
  margin-top: calc(0.5 * var(--theme-spacing-unit));
}
.root h2 {
  font-size: 24px;
  font-weight: 500;
}
.root h3 {
  font-size: 20px;
  font-weight: bold;
}
.root p {
  color: #333333;
  font-size: 16px;
}

.centered {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  margin-bottom: calc(8 * var(--theme-spacing-unit));
}

.current-page-num {
  font-size: 20px;
}

.max-page-num {
  font-size: 14px;
}

.logo {
  width: 72px;
  height: 72px;
  margin-right: 28px;
  background-color: #3e87a8;
}

.company-name {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: var(--theme-spacing-unit);
}

.side-by-side {
  display: flex;
}
.side-by-side > :not(:last-child) {
  margin-right: calc(6 * var(--theme-spacing-unit));
}
.side-by-side > * {
  width: 480px;
}
.side-by-side h2:first-child {
  margin-top: 0;
}

.side-by-side aside {
  color: #545454;
}

.card {
  display: flex;
  flex-direction: column;
  padding: calc(3 * var(--theme-spacing-unit));
}
.card header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: calc(3 * var(--theme-spacing-unit));
}

.resend {
  font-size: 14px;
}

.resend-label {
  color: var(--theme-palette-grey-400);
}

.verify {
  width: 160px;
}

.arrow-icon {
  width: 20px;
  height: 20px;
  margin-left: var(--theme-spacing-unit);
}
