.root {
  background-color: var(--theme-palette-primary-main);
}

.message {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.message > :first-child {
  margin-right: calc(2 * var(--theme-spacing-unit));
}
