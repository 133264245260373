.paper > h3 {
  margin-top: 0;
  margin-bottom: var(--theme-spacing-unit);
}
.paper > p {
  margin-top: 0;
  margin-bottom: calc(var(--theme-spacing-unit) * 2);
}

.logo {
  line-height: 1.15em;
  margin-top: 0;
  margin-bottom: calc(var(--theme-spacing-unit) * 4);
}
.logo-tuple {
  color: var(--theme-palette-grey-800);
  font-weight: 700;
}
.logo-health {
  color: var(--theme-palette-grey-800);
  font-weight: 700;
  margin-left: var(--theme-spacing-unit);
}
.logo-subhead {
  color: var(--theme-palette-grey-600);
  font-weight: 400;
  display: block;
  font-size: 1rem;
}
