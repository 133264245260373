.root {
  padding: calc(var(--theme-spacing-unit) * 2);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: orange;
  border-radius: 2px;
  margin-bottom: calc(2 * var(--theme-spacing-unit));
}

.icon {
  margin-right: var(--theme-spacing-unit);
  color: var(--theme-palette-grey-900);
}

.typography-title,
.typography-body {
  color: var(--theme-palette-grey-900);
}

.typography-title {
  font-weight: bold;
}

.typography-body {
  font-size: 0.875rem;
  line-height: 1.4em;
}

.link:link,
.link:visited {
  color: var(--theme-palette-grey-900);
  font-weight: bold;
  text-decoration: underline;
}
