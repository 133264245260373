.paper {
  display: flex;
  flex-direction: row;
  max-width: 900px;
}

.title {
  font-size: 28px;
  font-weight: normal;
}

.categories {
  display: flex;
}
.categories > * {
  flex-grow: 1;
}

.category-icon {
  display: flex;
  align-items: stretch;
  color: #1989ac;
}
.category-icon > * {
  width: 48px;
  height: 48px;
}

.category-label {
  margin: 20px 0;
  font-size: 16px;
  font-weight: bold;
}

.actions {
  justify-content: flex-start;
  padding: 0 calc(3 * var(--theme-spacing-unit)) calc(4 * var(--theme-spacing-unit));
  padding-top: 0;
  margin: 0;
}
.actions button {
  width: 228px;
  margin: 0;
}

.about-us {
  min-width: 240px;
  width: 240px;
  padding: calc(3 * var(--theme-spacing-unit));
  padding-top: 82px;
  background-color: #f2f2f2;
  color: #545454;
}

.about-us h2 {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.about-us p {
  font-size: 14px;
}

.content > p:first-child {
  margin-top: 0;
  margin-bottom: calc(3 * var(--theme-spacing-unit));
}
