.paper {
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-left: none;
  overflow-x: hidden;
}

.expander {
  position: relative;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}

.content-open {
  /* for marking purposes only */
}

.nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: content-box;
  background-color: white;
  /* z-index: calc(1 + var(--theme-zIndex-drawer)); */
  /* ^ the above doesn't work in IE11 due to incomplete calc() support */
  z-index: 1201;
}

.nav-header {
  height: calc(8 * var(--theme-spacing-unit));
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  display: flex;
}

.nav-button {
  padding: 0px;
}

.menu-icon {
  margin-right: 5px;
}

.divider {
  margin-top: 10px;
  margin-bottom: 10px;
}
