.app-bar {
  background: #e8f1f5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}

.toolbar {
  padding: 0 calc(2 * var(--theme-spacing-unit));
}
.toolbar > :not(:last-child) {
  margin-right: var(--theme-spacing-unit);
}

.toolbar > :last-child {
  margin-left: var(--theme-spacing-unit);
}

.toolbar button:hover {
  background-color: #d1d9dd;
}

.app-brand-title {
  color: #000000;
  margin-left: calc(2 * var(--theme-spacing-unit));
}

.filler {
  flex-grow: 1;
}

.customer-menu {
  border-left: 1px solid #d1d9dd;
  padding: 0 0 0 calc(2 * var(--theme-spacing-unit));
}

.messaging {
  color: white;
  min-width: 0;
  margin-left: calc(1.5 * var(--theme-spacing-unit));
}

.sidebar-paper {
  width: 285px;
  background-color: #f8f8f8;
  border-right: none;
  z-index: 0;
}

.sidebar-label {
  font-weight: bold;
  font-size: 19px;
  margin-bottom: 10px;
  padding: 0 calc(2 * var(--theme-spacing-unit));
}

.location-bar {
  position: fixed;
  z-index: 2; /* higher than refine menu labels */
  padding-top: var(--theme-spacing-unit);
  padding-left: var(--theme-spacing-unit);
  border-bottom: 1px solid #ddd;
  background-color: white;
}

.content {
  position: absolute;
  right: 0;
  bottom: 0;
}

.main {
  overflow-y: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* right is specified in JS */
}
